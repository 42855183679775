import './GroupScorecard.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const connection = process.env.REACT_APP_API_URL;

const GroupScorecard = () => {
  const [failedAuth, setFailedAuth] = useState(false);
  const [groupScores, setGroupScores] = useState([]);
  const [holes, setHoles] = useState([]);
  const { tourid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          setFailedAuth(true);
          return;
        }

        // Fetch group scores
        const scoresResponse = await axios.get(`${connection}/tours/scores/${tourid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const rounds = scoresResponse.data.rounds || [];
        setGroupScores(rounds);

        // Fetch holes for the course (only fetch once)
        if (rounds.length) {
          const courseId = rounds[0]?.course_id;
          if (courseId) {
            const holesResponse = await axios.get(`${connection}/courses/${courseId}/holes`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            setHoles(holesResponse.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setFailedAuth(true);
      }
    };

    fetchData();
  }, [tourid]);

  const timestampToDdMmYyyyId = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate().toString().padStart(2, '0')} / ${
      (date.getMonth() + 1).toString().padStart(2, '0')
    } / ${date.getFullYear()}`;
  };

  const getStyledScore = (score, par) => {
    if (typeof score !== 'number' || isNaN(score) || typeof par !== 'number' || isNaN(par)) {
      return <span className="score-error">-</span>;
    }
    if (score === par) {
      return <span className="score-par">{score}</span>;
    } else if (score < par) {
      return <span className="score-less-than-par">{score}</span>;
    }
    return <span className="score-more-than-par">{score}</span>;
  };

  if (failedAuth) {
    return (
      <section className="loading">
        <h3 className="loading__title">Authentication Failed</h3>
        <button><a href="/login">Login Again</a></button>
      </section>
    );
  }

  if (!groupScores.length || !holes.length) {
    return (
      <section className="loading">
        <h3 className="loading__title">Loading Scores...</h3>
      </section>
    );
  }

  return (
    <div className="container">
      {groupScores.map((data, index) => (
        <section className="scorecard" key={index}>
          <div className="scorecard__titles">
            <h3 className="scorecard__course">{data.course_name || 'Unknown Course'}</h3>
            <h3 className="scorecard__date">{timestampToDdMmYyyyId(data.round_date)}</h3>
          </div>
          <table className="scorecard__table">
            <tbody>
              {/* Front 9 */}
              <tr>
                <th>Hole</th>
                {holes.slice(0, 9).map((hole) => (
                  <td key={hole.hole_number}>{hole.hole_number}</td>
                ))}
                <td>Out</td>
              </tr>
              <tr>
                <th>Par</th>
                {holes.slice(0, 9).map((hole) => (
                  <td key={hole.hole_number}>{hole.par || '-'}</td>
                ))}
                <td>
                  {holes.slice(0, 9).reduce((acc, hole) => acc + (hole.par || 0), 0)}
                </td>
              </tr>
              {Object.values(data.users).map((user, userIdx) => (
                <tr key={`user-front-${userIdx}`}>
                  <th>{`${user.first_name} ${user.last_name}`}</th>
                  {user.scores.slice(0, 9).map((score, idx) => (
                    <td key={idx}>{getStyledScore(score.user_score, holes[idx]?.par)}</td>
                  ))}
                  <td>
                    {user.scores.slice(0, 9).reduce((acc, score) => acc + (score.user_score || 0), 0)}
                  </td>
                </tr>
              ))}

              {/* Back 9 */}
              {holes.length > 9 && (
                <>
                  <tr>
                    <th>Hole</th>
                    {holes.slice(9).map((hole) => (
                      <td key={hole.hole_number}>{hole.hole_number}</td>
                    ))}
                    <td>In</td>
                  </tr>
                  <tr>
                    <th>Par</th>
                    {holes.slice(9).map((hole) => (
                      <td key={hole.hole_number}>{hole.par || '-'}</td>
                    ))}
                    <td>
                      {holes.slice(9).reduce((acc, hole) => acc + (hole.par || 0), 0)}
                    </td>
                  </tr>
                  {Object.values(data.users).map((user, userIdx) => (
                    <tr key={`user-back-${userIdx}`}>
                      <th>{`${user.first_name} ${user.last_name}`}</th>
                      {user.scores.slice(9).map((score, idx) => (
                        <td key={idx}>{getStyledScore(score.user_score, holes[idx + 9]?.par)}</td>
                      ))}
                      <td>
                        {user.scores.slice(9).reduce((acc, score) => acc + (score.user_score || 0), 0)}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </section>
      ))}
    </div>
  );
};

export default GroupScorecard;
