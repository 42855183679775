import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './TournamentCard.scss';
import { FaTrophy, FaGolfBall, FaCheckCircle } from 'react-icons/fa'; // Import icons

const TournamentCard = ({ tournament_id, tournament_name, courses, description }) => {
  const navigate = useNavigate();
  const [hasJoined, setHasJoined] = useState(false);

  useEffect(() => {
    const checkIfJoined = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) throw new Error("User is not authenticated");

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tournaments/${tournament_id}/is-joined`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setHasJoined(response.data.joined);
      } catch (error) {
        console.error("Error checking join status:", error);
      }
    };

    checkIfJoined();
  }, [tournament_id]);

  const handleViewProgress = () => navigate(`/tournamentdetails/${tournament_id}`);

  const handleJoinTournament = async () => {
    try {
      const token = localStorage.getItem("jwtToken");
      if (!token) throw new Error("User is not authenticated");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tournaments/${tournament_id}/join`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 201) setHasJoined(true);
    } catch (error) {
      console.error("Error joining the tournament:", error);
    }
  };

  return (
    <div className="tournament__card">
      <div className="tournament__card--header">
        <FaTrophy className="header__icon" />
        {hasJoined && <span className="status-badge">Joined</span>}
      </div>

      <h3 className="tournament__card--title">{tournament_name}</h3>
      <p className="tournament__card--description">{description}</p>

      <ul className="tournament__card--courses">
        {courses.map((course, index) => (
          <li key={index} className="course__item">
            <FaGolfBall className="course__icon" /> {course.course_name}
          </li>
        ))}
      </ul>

      {!hasJoined ? (
        <button className="tournament__card--button" onClick={handleJoinTournament}>
          Add To Tournaments
        </button>
      ) : (
        <button className="tournament__card--button" onClick={handleViewProgress}>
          View Progress
        </button>
      )}
    </div>
  );
};

export default TournamentCard;
