import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './TournamentProgress.scss';

const TournamentProgress = () => {
  const { tournamentId } = useParams(); // Get tournamentId from URL
  const [userProgress, setUserProgress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        if (!token) {
          throw new Error("User is not authenticated");
        }

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/tournaments/${tournamentId}/progress`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        setUserProgress(response.data);
        setLoading(false);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError("You are not signed up for this tournament or have no progress yet.");
        } else {
          setError(err.message);
        }
        setLoading(false);
      }
    };

    fetchUserProgress();
  }, [tournamentId]);

  if (loading) {
    return <p>Loading progress...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!userProgress) {
    return <div className='no__tournament'>Tournament not found!</div>;
  }

  const completedCourses = userProgress.courses.filter(course => course.completed).length;
  const totalCourses = userProgress.courses.length;

  return (
    <div className="tournament--progress__container">
      <h2 className="tournament--progress__title">Your Progress in {userProgress.tournament_name}</h2>

      <div className="tournament--progress__card">
        <h3 className='tournament--progress__card-subtitle'>{userProgress.tournament_name}</h3>
        <div className="tournament--progress__graphic">
          <div className="tournament--progress__bar" style={{ width: `${(completedCourses / totalCourses) * 100}%` }}></div>
        </div>

        <ul className="tournament--progress__course-list">
          {userProgress.courses.map((course, index) => (
            <li key={index} className="tournament--progress__course">
              <span className='tournament--progress__score'>{course.course_name}: {course.completed ? `${course.score}` : 'Incomplete'}</span>
            </li>
          ))}
        </ul>

        <div className="tournament--progress__summary">
          <p className='tournament--progress__total-score'>Total Score: {userProgress.totalScore ? userProgress.totalScore : 'Incomplete'}</p>
        </div>
      </div>
    </div>
  );
};

export default TournamentProgress;
