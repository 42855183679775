import './RegistrationPage.scss';
import Register from '../../components/Register/Register';
import Header from '../../components/LoginForm/LoginForm';

const RegistrationPage = () => {
    return (
        <div className="background">
            <Register />
        </div>
    );
};

export default RegistrationPage;