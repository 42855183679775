import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CreateGroup.scss';
import { useNavigate } from 'react-router-dom';

const connection = process.env.REACT_APP_API_URL;

const CreateGroup = () => {
  const navigate = useNavigate();
  const [partyName, setPartyName] = useState('');
  const [matchType, setMatchType] = useState('');
  const [description, setDescription] = useState('');
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [friendSearchTerm, setFriendSearchTerm] = useState('');
  const [friendSuggestions, setFriendSuggestions] = useState([]);
  const [createdByUserId, setCreatedByUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${connection}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCreatedByUserId(response.data.user_id);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchCurrentUser();
  }, []);

  const handleSearchChange = async (value) => {
    setFriendSearchTerm(value);

    if (value.length > 1) {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${connection}/friends/search`, {
          params: { query: value },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setFriendSuggestions(response.data.friends);
      } catch (error) {
        console.error('Error fetching friend suggestions:', error);
      }
    } else {
      setFriendSuggestions([]);
    }
  };

  const addFriendToList = (friend) => {
    if (!selectedFriends.some(f => f.user_id === friend.user_id)) {
      setSelectedFriends([...selectedFriends, friend]);
      setFriendSuggestions([]);
      setFriendSearchTerm('');
    }
  };

  const removeFriend = (userId) => {
    setSelectedFriends(selectedFriends.filter(friend => friend.user_id !== userId));
  };

  const createTour = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      const userIds = selectedFriends.map(friend => friend.user_id);
      const response = await axios.post(`${connection}/newtour`, {
        userIds,
        partyName,
        description,
        createdByUserId,
        matchType,
      });

      setLoading(false);
      setSuccess('Tour created successfully!');
      navigate('/tourslist');
    } catch (error) {
      setLoading(false);
      setError('Error creating tour. Please try again.');
      console.error('Error creating tour:', error);
    }
  };

  return (
    <section className='make-group__container'>
      <div className="make-group__header">
        <h2 className="make-group__header--title">Start Your Tour</h2>
      </div>
      <form onSubmit={createTour} className='make-group'>
        <label htmlFor="partyName" className='make-group__label'>Tour Name:</label>
        <input type="text" id="partyName" value={partyName} onChange={(e) => setPartyName(e.target.value)} required className='make-group__input'/>

        <div className="make-group__type">
          <label htmlFor="match-choice" className="make-group__label">Match Format:</label>
          <select name="match-choice" id="match-choice" value={matchType} onChange={(e) => setMatchType(e.target.value)} className="make-group__input">
            <option value="" disabled>Choose Match Format</option>
            <option value="stroke">Stroke Play</option>
            <option value="match">Match Play</option>
          </select>
        </div>

        <label className="make-group__label">Add Friends:</label>
        <input
          type="text"
          value={friendSearchTerm}
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder="Search for friends..."
          className="make-group__input"
        />
        {friendSuggestions.length > 0 && (
          <ul className="friend-suggestions">
            {friendSuggestions.map(friend => (
              <li key={friend.user_id} onClick={() => addFriendToList(friend)} className="friend-suggestion">
                {friend.first_name} {friend.last_name}
              </li>
            ))}
          </ul>
        )}

        <div className="selected-friends">
          {selectedFriends.map(friend => (
            <div key={friend.user_id} className="selected-friend">
              {friend.first_name} {friend.last_name}
              <button type="button" onClick={() => removeFriend(friend.user_id)}>Remove</button>
            </div>
          ))}
        </div>

        <button type="submit" className='form__button' disabled={loading}>{loading ? 'Creating...' : 'Submit'}</button>
        {error && <p className='error-message'>{error}</p>}
        {success && <p className='success-message'>{success}</p>}
      </form>
    </section>
  );
};

export default CreateGroup;
