import React, { useState, useEffect } from 'react';
import './ActivityFeed.scss';
import ActivityTile from '../../components/ActivityTile/ActivityTile';
import axios from 'axios';

const ActivityFeed = () => {
    const [activities, setActivities] = useState([]);
    const [failedAuth, setFailedAuth] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // For pagination
    const [totalRounds, setTotalRounds] = useState(0); // To track total rounds for pagination

    // Fetch activities when the component mounts
    useEffect(() => {
        fetchActivities(page);
    }, [page]);


    // Use the correct base URL
    const connection = process.env.REACT_APP_API_URL;
    
    const fetchActivities = async (page) => {
        setLoading(true);
        setError(null);
    
        try {
            const token = localStorage.getItem("jwtToken");
            if (!token) {
                return setFailedAuth(true);
            }
    
            const response = await axios.get(`${connection}/newsfeed/friends-rounds`, {
                params: { page, limit: 10 },
                headers: {
                    Authorization: `Bearer ${token}`, // Add the authorization header
                },
            });
    
            setActivities(response.data.rounds); // Set activities from the API response
            setTotalRounds(response.data.totalRounds); // Set total rounds for pagination

        } catch (err) {
            console.error('Error fetching activities:', err);
            setError('Failed to load activities.');
        } finally {
            setLoading(false);
        }
    };
    
    

    // Handle loading next/previous pages for pagination
    const handleNextPage = () => {
        if ((page * 10) < totalRounds) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className='activity-feed'>
            <h2 className="activity-feed__title">Your Club Activities</h2>

            {loading ? (
                <p>Loading activities...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : activities.length === 0 ? (
                <p>No recent activities from friends.</p>
            ) : (
                activities.map((activity, index) => (
                    <ActivityTile key={index} activityData={activity} />
                ))
            )}

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>
                <span>Page {page}</span>
                <button onClick={handleNextPage} disabled={(page * 10) >= totalRounds}>Next</button>
            </div>
        </div>
    );
};

export default ActivityFeed;
