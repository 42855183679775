import React, { useState, useEffect, useCallback } from 'react';
import './ActivityTile.scss';
import axios from 'axios';

const connection = process.env.REACT_APP_API_URL;

const ActivityTile = ({ activityData }) => {
    const [failedAuth, setFailedAuth] = useState(false);
    const [showScorecard, setShowScorecard] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [liked, setLiked] = useState(false);
    const [likes, setLikes] = useState(activityData.likes || 0);
    const [comments, setComments] = useState(activityData.comments || []);
    const [newComment, setNewComment] = useState('');

      // Format the game date
      const formattedGameDate = new Date(activityData.game_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
  
    // Use useCallback to avoid re-creating the fetchInteractions function on each render
    const fetchInteractions = useCallback(async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          return setFailedAuth(true);
        }
  
        const response = await axios.get(`${connection}/newsfeed/interaction/${activityData.round_id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        setLikes(response.data.likes);
        setComments(response.data.comments);
  
        // Check if the current user has liked the post
        const userLiked = response.data.likedByCurrentUser;
        setLiked(userLiked);
      } catch (error) {
        console.error('Error fetching interactions:', error);
        setFailedAuth(true);
      }
    }, [activityData.round_id]); // Add activityData.round_id as a dependency
  
    useEffect(() => {
      fetchInteractions(); // No need to add fetchInteractions to the dependency array anymore
    }, [fetchInteractions]);

  // Toggle the scorecard visibility
  const toggleScorecard = () => {
    setShowScorecard(!showScorecard);
  };

  // Toggle the comments section visibility
  const toggleComments = () => {
    setShowComments(!showComments);
  };

    // Handle adding a comment
    const handleAddComment = async () => {
        if (newComment.trim()) {
          try {
            const token = localStorage.getItem("jwtToken");
            if (!token) {
              return setFailedAuth(true);
            }
    
            await axios.post(`${connection}/newsfeed/comment`, 
            {
              round_id: activityData.round_id,
              comment_text: newComment,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
    
            setComments([...comments, { userName: 'Current User', comment_text: newComment }]);
            setNewComment('');
          } catch (error) {
            console.error('Error adding comment:', error);
            setFailedAuth(true);
          }
        }
      };

      const handleLike = async () => {
        try {
          const token = localStorage.getItem("jwtToken");
      
          if (!token) {
            return setFailedAuth(true);  // If no token, user must log in again
          }
      
          // Send the like request only if the post has not already been liked
          if (!liked) {
            const response = await axios.post(`${connection}/newsfeed/like`, 
              { round_id: activityData.round_id },  // Ensure round_id is passed
              {
                headers: {
                  Authorization: `Bearer ${token}`,  // Pass the token
                },
              }
            );
      
            if (response.status === 200) {
              setLikes(likes + 1);  // Increment the likes count
              setLiked(true);  // Mark the post as liked
            }
          }
        } catch (error) {
          // Handle the authorization error or any other error
          if (error.response && error.response.status === 401) {
            setFailedAuth(true);  // Trigger login if authorization fails
          } else if (error.response && error.response.status === 400) {
            console.error('Error:', error.response.data.message);  // Handle 400 errors
          } else {
            console.error('Error liking post:', error);
          }
        }
      };      

  // Render the scorecard (split front 9 and back 9)
  const renderScorecard = () => (
    <div className="activity__tile--scorecard">
      <div className="scorecard__section">
        <h4>Front 9</h4>
        <table className="scorecard__table">
          <thead>
            <tr>
              <th>Hole</th>
              {activityData.holes.slice(0, 9).map((hole, index) => (
                <th key={index}>{hole.hole_number}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Par</td>
              {activityData.holes.slice(0, 9).map((hole, index) => (
                <td key={index}>{hole.par}</td>
              ))}
            </tr>
            <tr>
              <td>Score</td>
              {activityData.scores.slice(0, 9).map((score, index) => (
                <td key={index} className={getStyledScoreClass(score.hole_score, activityData.holes[index].par)}>
                  {score.hole_score || '-'}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {activityData.holes.length > 9 && (
        <div className="scorecard__section">
          <h4>Back 9</h4>
          <table className="scorecard__table">
            <thead>
              <tr>
                <th>Hole</th>
                {activityData.holes.slice(9, 18).map((hole, index) => (
                  <th key={index}>{hole.hole_number}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Par</td>
                {activityData.holes.slice(9, 18).map((hole, index) => (
                  <td key={index}>{hole.par}</td>
                ))}
              </tr>
              <tr>
                <td>Score</td>
                {activityData.scores.slice(9, 18).map((score, index) => (
                  <td key={index} className={getStyledScoreClass(score.hole_score, activityData.holes[index + 9].par)}>
                    {score.hole_score || '-'}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );

  // Get CSS class for score styling
  const getStyledScoreClass = (score, par) => {
    if (score === par) return 'score-par';
    if (score < par) return 'score-less-than-par';
    return 'score-more-than-par';
  };

  if (failedAuth) {
    return (
      <section className='error'>
        <h3 className="error__title">Authorization Error</h3>
        <button><a href='/login'>Login Again</a></button>
      </section>
    );
  }

  return (
    <section className="activity">
      <div className="activity__tile">
        <div className="activity__tile--user">
          <img src={activityData.userImage} alt="User" className="activity__img" />
          <div className="activity__user">
            <h4 className="activity__user--name">{activityData.first_name} {activityData.last_name}</h4>
            <h6 className="activity__user--date">{formattedGameDate}</h6>
          </div>
        </div>

        <div className="activity__tile--stats">
          <div className="activity__stats">
            <h4 className="activity__stats--title">Score:</h4>
            <h4 className="activity__stats--score">{activityData.total_score}</h4>
          </div>
          <div className="activity__stats">
            <h4 className="activity__stats--title">F.H.:</h4>
            <h4 className="activity__stats--score">{activityData.fairways_hit_percentage} %</h4>
          </div>
          <div className="activity__stats">
            <h4 className="activity__stats--title">G.I.R.:</h4>
            <h4 className="activity__stats--score">{activityData.greens_in_regulation_percentage} %</h4>
          </div>
          <div className="activity__stats">
            <h4 className="activity__stats--title">2-Putts:</h4>
            <h4 className="activity__stats--score">{activityData.two_putts_percentage} %</h4>
          </div>
        </div>

        <h3 className="activity__tile--course">{activityData.course_name}</h3>
        <button className="activity__toggle-button" onClick={toggleScorecard}>
          {showScorecard ? 'Hide Scorecard' : 'View Scorecard'}
        </button>
        {showScorecard && renderScorecard()}

        {/* Likes and Comments Section */}
        <div className="activity__actions">
            <button 
                className={`activity__like-button ${liked ? 'liked' : ''}`} 
                onClick={handleLike}
                disabled={liked}  // Disable the button once the post is liked
            >
                {liked ? 'Liked' : 'Like'} ({likes})
            </button>
            <button className="activity__toggle-comments-button" onClick={toggleComments}>
                {showComments ? 'Hide Comments' : 'Add Comment'}
            </button>
        </div>


        {/* Comments Section */}
        {showComments && (
          <div className="activity__comments-section">
            <h4>Comments</h4>
            <ul className="activity__comments-list">
              {comments.map((comment, index) => (
                <li key={index} className="activity__comment">
                  <strong>{comment.userName}:</strong> {comment.comment_text}
                </li>
              ))}
            </ul>

            {/* Add Comment Section */}
            <div className="activity__add-comment">
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
              />
              <button onClick={handleAddComment}>Post</button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ActivityTile;
