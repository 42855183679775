import { useState, useEffect } from 'react';
import axios from 'axios';
import './TournamentsList.scss';
import TournamentCard from '../../components/TournamentCard/TournamentCard';

const connection = process.env.REACT_APP_API_URL;

const TournamentsList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [failedAuth, setFailedAuth] = useState(false);

  useEffect(() => {
    // Fetch tournaments from the backend
    const fetchTournaments = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        if (!token) {
          setError("Authorization failed. Please log in.");
          setLoading(false);
          setFailedAuth(true);
          return;
        }

        const response = await axios.get(`${connection}/tournaments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setTournaments(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load tournaments');
        setLoading(false);
      }
    };

    fetchTournaments();
  }, []);

  // console.log(tournaments);

  // Filter tournaments based on the search term
  const filteredTournaments = tournaments.filter(tournament =>
    tournament.tournament_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <p>Loading tournaments...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className='tournaments__container'>
      <div className="tournaments__container--header">
        <h2 className='tournaments__container--title'>Available Tournaments</h2>
      </div>

      {/* Search Input */}
      <div className='tournaments__search'>
        <input 
          type='text' 
          placeholder='Search tournaments...'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Display Filtered Tournaments */}
      {filteredTournaments.map(tournament => (
        <TournamentCard key={tournament.tournament_id} {...tournament} />
      ))}
    </div>
  );
};

export default TournamentsList;
