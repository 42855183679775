import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LeaderBoard from '../../components/LeaderBoard/LeaderBoard';
import GroupScorecard from '../../components/GroupScorecard/GroupScorecard';
import './LeaderBoardPage.scss';

const LeaderBoardPage = () => {
  const { tourid } = useParams();
  const [tourName, setTourName] = useState('');
  const [groupScores, setGroupScores] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const connection = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTourData = async () => {
      try {
        const token = localStorage.getItem('jwtToken');

        const tourResponse = await axios.get(`${connection}/tours/${tourid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const scoresResponse = await axios.get(`${connection}/tours/scores/${tourid}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log('Tour Response:', tourResponse.data);
        console.log('Scores Response:', scoresResponse.data);

        setTourName(tourResponse.data.party_name || 'Unknown Tour');
        setLeaderboard(scoresResponse.data.users || []);
        setGroupScores(scoresResponse.data.rounds || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching tour data:', error);
        setIsLoading(false);
      }
    };

    fetchTourData();
  }, [tourid, connection]);

  console.log('Group Scores State:', groupScores);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="leaderboard__page">
      <div className="leaderboard__header">
        <h1 className="leaderboard__title">{tourName}</h1>
      </div>

      {/* Leaderboard */}
      <section className="leaderboard__section">
        <LeaderBoard data={leaderboard} />
      </section>

      {/* Historical Scorecards */}
      <section className="scorecards__section">
        <h2 className="scorecards__header">Historical Scorecards</h2>
            {groupScores.length > 0 ? (
                groupScores.map((scoreData, index) => {
                    console.log('Score Data:', scoreData); // This log must appear
                    return <GroupScorecard key={index} groupData={scoreData} />;
                })
                ) : (
                <p>No historical scorecards available.</p>
            )}
      </section>
    </div>
  );
};

export default LeaderBoardPage;
