// LoginPage.jsx
import React, { useState, useEffect } from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';
import Spinner from '../../components/Spinner/Spinner';
import './LoginPage.scss';

const LoginPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Simulate loading delay
    }, 2000); // Adjust the duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="background">
      {loading ? <Spinner /> : <LoginForm />}
    </div>
  );
};

export default LoginPage;
