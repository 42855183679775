import './UserProfileCard.scss';
import axios from "axios";
import Spinner from '../Spinner/Spinner';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

const connection = process.env.REACT_APP_API_URL;

const UserProfileCard = () => {
    const [user, setUser] = useState(null);
    const [numRounds, setNumRounds] = useState(null);
    const [loading, setLoading] = useState(true);
    const [failedAuth, setFailedAuth] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("jwtToken");

                if (!token) {
                    setFailedAuth(true);
                    return;
                }

                const responseUser = await axios.get(`${connection}/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setUser(responseUser.data);
            } catch (error) {
                console.error(error);
                setFailedAuth(true);
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        const fetchNumRounds = async () => {
            try {
                const token = localStorage.getItem("jwtToken");

                if (!token) {
                    setFailedAuth(true);
                    return;
                }

                const responseNumRounds = await axios.get(`${connection}/numrounds`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });


                setNumRounds(responseNumRounds.data.numRounds);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setFailedAuth(true);
                setLoading(false);
            }
        };

        fetchNumRounds();
    }, []);

    if (failedAuth || user === null || loading) {
        return (
            <section className='loading'>
                <h3 className="loading__title">Authentication failed. Please log in.</h3>
                <button><Link to='/login'>Please Log In</Link></button>
            </section>
        );
    }

    if (loading) {
        return <Spinner />; // Render Spinner component during loading
      }

    return (
        <section className='profile-card'>
            <img 
                src={user && user.profile_image_path ? `${connection}/profileUploads/${user.profile_image_path}?t=${Date.now()}` : 'default-profile-image-url'} 
                alt="Profile" 
                className='user__image'
            />
            <div className="user">
                <span className='user__name'>
                    <h2 className="user__first">{user.first_name}</h2>
                    <h2 className="user__last">{user.last_name}</h2>
                </span>
                <div className="user__stats">
                    <div className="user__history">
                        <h5 className="user__label">No. Rounds:</h5>
                        <h5 className="user__rounds-played">{numRounds}</h5>
                    </div>
                    <div className="user__location">
                        <h5 className="user__label">Location:</h5>
                        <h5 className="user__city">
                            {user.city ? user.city : "Not entered"}, {user.province ? user.province : "Not entered"}
                        </h5>
                    </div>
                </div>
                <div className="user__buttons-container">
                    <Link to='/start' className="user__start-round">Start Round</Link>
                    <Link to='/grouproundstart' className="user__start-tour">Start Tour</Link>
                </div>
            </div>
        </section>
    );
};

export default UserProfileCard;
