import './TourTiles.scss';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

const connection = process.env.REACT_APP_API_URL;

const TourTiles = () => {
    const [userGroups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTours = async () => {
            const token = localStorage.getItem("jwtToken");

            if (!token) {
                setLoading(false);
                setError('Authentication failed. Please log in.');
                return;
            }

            try {
                const response = await axios.get(`${connection}/tours`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setUserGroups(response.data.userTours);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch tours. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchTours();
    }, []);

    if (loading) {
        return <p>Loading...</p>; // You can replace this with a loading spinner or animation
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <section className='tours'>
            {userGroups.map((group, index) => (
                <div className="tours__tile" key={index}>
                    <h4 className="tour__name">{group.party_name}</h4>
                    
                    <Link to={`/tours/${group.party_id}`}>
                        <button className='tour__button'>View</button>
                    </Link>
                </div>
            ))}
        </section>
    );
};

export default TourTiles;