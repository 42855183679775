import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TournamentLeaderboard.scss';

const TournamentLeaderboard = ({ tournamentId }) => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchLeaderboard = async () => {
    try {
      const token = localStorage.getItem("jwtToken");

      if (!token) throw new Error("User is not authenticated");

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/leaderboard/${tournamentId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.length === 0) {
        setError("No leaderboard data available.");
      } else {
        setLeaderboard(response.data);
      }

      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || "Error fetching leaderboard data.");
      setLoading(false);
    }
  };

  // UseEffect to fetch leaderboard on component mount or when tournamentId changes
  useEffect(() => {
    fetchLeaderboard();
  }, [tournamentId]); // Ensure dependency on tournamentId

  if (loading) return <p>Loading leaderboard...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="tournament-leaderboard__container">
      <h3>Tournament Leaderboard</h3>
      {leaderboard.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Rank</th>
              <th>Player</th>
              <th>Total Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{player.player}</td>
                <td>{player.total_score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leaderboard data available yet.</p>
      )}
    </div>
  );
};

export default TournamentLeaderboard;
