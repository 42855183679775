import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FriendsSearch.scss';

const FriendsSearch = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [failedAuth, setFailedAuth] = useState(false);
    const [userId, setUserId] = useState(null); // Track current user ID
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [sentRequests, setSentRequests] = useState([]); // Track sent friend requests

    const connection = process.env.REACT_APP_API_URL;

    // Fetch logged-in user data (use this to set userId)
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                if (!token) {
                    setFailedAuth(true);
                    return;
                }

                // Get user data from API
                const response = await axios.get(`${connection}/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setUserId(response.data.user_id); // Set the logged-in user's ID
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const token = localStorage.getItem("jwtToken");
            if (!token) {
                return setFailedAuth(true);
            }

            const response = await axios.get(`${connection}/search?query=${encodeURIComponent(searchTerm)}`, {
                headers: {
                   Authorization: `Bearer ${token}`
                }
            });
            
            setSearchResults(response.data);
        } catch (err) {
            setError('An error occurred while searching. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Send friend request to user
const handleSendRequest = async (friend_user_id) => {

    try {
        const token = localStorage.getItem('jwtToken');
        if (!token) {
            setFailedAuth(true);
            return;
        }

        await axios.post(`${connection}/send-request`, { friend_user_id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // Update the sent requests state
        setSentRequests([...sentRequests, friend_user_id]);
    } catch (err) {
        console.error('Error sending friend request:', err);
        setError('An error occurred while sending friend request.');
    }
};

// Check if a friend request has been sent
const isRequestSent = (friend_user_id) => {
    return sentRequests.includes(friend_user_id);
};

    return (
        <div className="friend-search">
            <form onSubmit={handleSearch} className="friend-search__form">
                <input
                    type="text"
                    placeholder="Search for friends..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="friend-search__input"
                />
                <button type="submit" className="friend-search__button">
                    Search
                </button>
            </form>

            {loading && <p>Loading...</p>}
            {error && <p className="friend-search__error">{error}</p>}
            {!loading && !error && searchResults.length === 0 && searchTerm && (
                <p>No friends found matching your search.</p>
            )}

            <ul className="friend-search__results">
                {searchResults.map(user => (
                    <li key={user.user_id} className="friends--tile">
                        <img src={user.profile_image_path} alt={user.first_name} className="friends--tile__img" />
                        <div className="friends--tile__details">
                            <div className="friends--name-location">
                                <div className="friends--name">
                                    <h4 className="friends--name__first">{user.first_name}</h4>
                                    <h4 className="friends--name__last">{user.last_name}</h4>
                                </div>
                                <div className="friends--location">
                                    <h5 className="friends--location__city">{user.city}</h5>
                                    <h5 className="friends--location__province">{user.province}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="friends--tile__buttons">
                            <button
                                onClick={() => handleSendRequest(user.user_id)}
                                className="add-friend-button"
                                disabled={isRequestSent(user.user_id)} // Disable button if request already sent
                            >
                                {isRequestSent(user.user_id) ? 'Request Sent' : 'Add Friend'}
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FriendsSearch;
