import TourTiles from '../../components/TourTiles/TourTiles';
import './Tours.scss';
import { Link } from 'react-router-dom';
import addIcon from '../../assets/icons/add-icon.png';

const Tours = () => {
    return (
        <div className='tours__container'>
            <div className="tours__header">
            </div>

            <div className="tours__titles">
                <h1 className="tours__title">Your Tours</h1>
                <Link to='/createtour' className='tours__link'><img src={addIcon} alt="make new group"/>New Group</Link>
            </div>

            <section className="tour__tiles-row">
                <TourTiles />
            </section>
        </div>
    );
};

export default Tours;