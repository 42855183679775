import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './DropDownHeader.scss';
import menuIcon from '../../assets/icons/menu-icon.svg';
import arrowDown from '../../assets/icons/arrow_drop_down-24px.svg';

const connection = process.env.REACT_APP_API_URL;

const DropDownHeader = () => {
  const [showList, setShowList] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const mobileMenuRef = useRef(null); // Reference for mobile menu
  const desktopDropdownRef = useRef(null); // Reference for desktop dropdown

  // useEffect hook to handle user data fetching
  useEffect(() => {
    // Skip user data fetch on public routes
    if (['/about', '/login', '/register', '/legalPage'].includes(location.pathname)) {
      setLoading(false); // No need to load user data for these pages
      return;
    }

    fetchUserData();
  }, [location.pathname]); // Re-run the effect when location changes

  const fetchUserData = async () => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/login'); // Redirect to login if no token is found
      setLoading(false); // Stop loading state
      return;
    }

    try {
      const response = await axios.get(`${connection}/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(response.data); // Set the user data
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response?.status === 401) navigate('/login'); // Redirect on 401 error
    } finally {
      setLoading(false); // Stop loading after data is fetched
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('jwtToken');
    setUser(null);
    navigate('/login');
  };

  const toggleMobileMenu = () => setShowList((prev) => !prev);
  const toggleDesktopDropdown = () => setShowMore((prev) => !prev);

  const handleOutsideClick = (e) => {
    // Close mobile menu if clicked outside
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(e.target)) {
      setShowList(false);
    }
    // Close desktop dropdown if clicked outside
    if (desktopDropdownRef.current && !desktopDropdownRef.current.contains(e.target)) {
      setShowMore(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  if (['/login', '/register', '/about', '/legalPage'].includes(location.pathname)) return null;

  if (loading) return <h1>Loading...</h1>;

  return (
    <div className="drop-header">
      {/* Desktop Navigation */}
      <nav className="drop-header__nav-desktop">
        <h2 className="drop-header__title">The Tour</h2>
        <div className="drop-header__links-container">
          <Link className="drop-header__link" to="/">Dashboard</Link>
          <Link className="drop-header__link" to="/activity">Activity Feed</Link>
          <Link className="drop-header__link" to="/start">Rounds</Link>
          <Link className="drop-header__link" to="/tourslist">Tours</Link>
          <Link className="drop-header__link" to="/findcourses">Courses</Link>
          <Link className="drop-header__link" to="/friendslist">Friends</Link>
          <Link className="drop-header__link" to="/tournaments">Tournaments</Link>
        </div>

        {user && (
          <div
            className="drop-header__container-desktop"
            ref={desktopDropdownRef}
          >
            <button className="drop-header__show-more" onClick={toggleDesktopDropdown}>
              <h3 className="show-more__letter">{user.first_name}</h3>
              <img src={arrowDown} alt="dropdown arrow" />
            </button>
            {showMore && (
              <ul className="drop-header__dropdown">
                <li>
                  <Link to="/editprofile" onClick={() => setShowMore(false)}>
                    Edit Profile
                  </Link>
                </li>
                <li>
                  <button onClick={handleLogout}>Sign Out</button>
                </li>
              </ul>
            )}
          </div>
        )}
      </nav>

      {/* Mobile Navigation */}
      <nav className="drop-header__nav-mobile">
        <h2 className="drop-header__title">The Tour</h2>
        <div className="drop-header__container" ref={mobileMenuRef}>
          <button className="drop-header__button" onClick={toggleMobileMenu}>
            <img src={menuIcon} alt="menu icon" />
          </button>

          <ul className={`drop-header__list ${showList ? 'show' : 'hide'}`}>
            <li className='drop-header__item'>
              <Link to="/" onClick={() => setShowList(false)} className='drop-header__link'>
                Dashboard
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/activity" onClick={() => setShowList(false)} className='drop-header__link'>
                Activity Feed
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/start" onClick={() => setShowList(false)} className='drop-header__link'>
                Start Round
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/tourslist" onClick={() => setShowList(false)} className='drop-header__link'>
                Tours
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/findcourses" onClick={() => setShowList(false)} className='drop-header__link'>
                Courses
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/friendslist" onClick={() => setShowList(false)} className='drop-header__link'>
                Friends
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/tournaments" onClick={() => setShowList(false)} className='drop-header__link'>
                Tournaments
              </Link>
            </li>
            <li className='drop-header__item'>
              <Link to="/editprofile" onClick={() => setShowList(false)} className='drop-header__link'>
                Edit Profile
              </Link>
            </li>
            <li className='drop-header__item'>
              <button onClick={handleLogout}>Sign Out</button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default DropDownHeader;
