import './TrackRound.scss';
import {useState, useEffect} from 'react';
import axios from 'axios';
import ScoreInput from '../../components/ScoreInput/ScoreInput';

const TrackRound = () => {
    return (
        <section>
            <ScoreInput />
        </section>
    );
};

export default TrackRound;