import React from 'react';
import './LegalPage.scss';

const LegalPage = () => {
    return (
        <div className="legal-page">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date:</strong> October 3, 2024</p>
            <p><strong>Type of website:</strong> Social Network for Golfers</p>
            <p>www.thetourgolfers.com (the “Site”) is owned and operated by Jack Jameson. Jack Jameson can be contacted at: <a href="mailto:thetourgolfers@gmail.com">thetourgolfers@gmail.com</a></p>

            <h2>Purpose</h2>
            <p>The purpose of this privacy policy (the “Privacy Policy”) is to inform users of our Site of the following:</p>
            <ul>
                <li>The personal data we collect</li>
                <li>Use of collected data</li>
                <li>Who has access to the data collected</li>
                <li>The rights of Site users</li>
                <li>The Site’s cookie policy</li>
            </ul>

            <h2>Consent</h2>
            <p>By using our Site, users agree that they consent to:</p>
            <ul>
                <li>The conditions set out in this Privacy Policy, and</li>
                <li>The collection, use, and retention of the data listed in this Privacy Policy.</li>
            </ul>

            <h2>Personal Data We Collect</h2>
            <p>We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without notifying you first.</p>
            <h3>Data Collected in a Non-Automatic Way</h3>
            <p>We may collect the following data when you perform certain functions on our Site:</p>
            <ul>
                <li>First and Last Name</li>
                <li>Date of Birth</li>
                <li>Location</li>
                <li>Email Address</li>
                <li>Golf Scores</li>
            </ul>

            <h2>How We Use Personal Data</h2>
            <p>Data collected on our Site will only be used for the purposes specified in this Privacy Policy.</p>

            <h2>Other Disclosures</h2>
            <p>We will not sell or share your data with other third parties, except in the following cases:</p>
            <ul>
                <li>If the law requires it;</li>
                <li>If it is required for any legal proceeding;</li>
                <li>To protect our legal rights; and</li>
                <li>To buyers or potential buyers of this company if we seek to sell the company.</li>
            </ul>

            <h2>How We Protect Your Personal Data</h2>
            <p>We implement robust security measures within our platform.</p>

            <h2>Children</h2>
            <p>The minimum age to use our website is 14 years. We do not knowingly collect or use personal data from children under 13 years.</p>

            <h2>How to Access, Modify, Delete, or Challenge Data Collected</h2>
            <p>To access, modify, delete, or inquire about your data, please contact us at:</p>
            <p><a href="mailto:thetourgolfers@gmail.com">thetourgolfers@gmail.com</a></p>

            <h2>Cookie Policy</h2>
            <p>We currently do not use cookies on our Site.</p>

            <h2>Modifications</h2>
            <p>This Privacy Policy may be amended periodically.</p>

            <h1>Terms and Conditions</h1>
            <p><strong>Effective Date:</strong> October 3, 2024</p>
            <p><strong>Type of website:</strong> Social Network for Golfers</p>

            <h2>Intellectual Property</h2>
            <p>All content published and made available on our Site is the property of Jack Jameson and the Site’s creators.</p>

            <h2>Age Restrictions</h2>
            <p>The minimum age to use our Site is 14 years. Users agree that they are over 14 years old.</p>

            <h2>Acceptable Use</h2>
            <p>As a user of our Site, you agree to:</p>
            <ul>
                <li>Not harass or mistreat other users,</li>
                <li>Not violate the rights of other users,</li>
                <li>Not infringe on intellectual property rights,</li>
                <li>Not hack into other user accounts,</li>
                <li>Not act fraudulently, and</li>
                <li>Avoid posting content that may be inappropriate or offensive.</li>
            </ul>

            <h2>Accounts</h2>
            <p>By creating an account, users agree to take responsibility for the security and privacy of their account information.</p>

            <h2>User Goods and Services</h2>
            <p>Our Site allows users to sell goods and services. We are not responsible for the quality or accuracy of user-sold goods and services.</p>

            <h2>Limitation of Liability</h2>
            <p>Jack Jameson and affiliates will not be liable for any actions, claims, losses, damages, liabilities, and expenses, including legal fees, from the use of the Site.</p>

            <h2>Applicable Law</h2>
            <p>These Terms are governed by the laws of the Province of Ontario, Canada.</p>

            <h2>Modifications</h2>
            <p>We may amend these Terms to maintain compliance with the law and reflect operational changes.</p>

            <h2>Contact Information</h2>
            <p>For any questions or concerns, please contact us at:</p>
            <p><a href="mailto:thetourgolfers@gmail.com">thetourgolfers@gmail.com</a></p>
        </div>
    );
};

export default LegalPage;
