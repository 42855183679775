import './LeaderBoard.scss';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const connection = process.env.REACT_APP_API_URL;



const LeaderBoard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [matchType, setMatchType] = useState('');
  const [loading, setLoading] = useState(true);
  const [failedAuth, setFailedAuth] = useState(false);
  const { tourid } = useParams();
  const token = localStorage.getItem('jwtToken');

  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await axios.get(`${connection}/tours/scores/${tourid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // console.log('Fetched Leaderboard Data:', response.data);

        setLeaderboardData(response.data.users);
        setMatchType(response.data.matchType.trim());
        setLoading(false);
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
        setFailedAuth(true);
        setLoading(false);
      }
    };

    fetchLeaderboardData();
  }, [tourid, token]);

  const calculatedLeaderboard = useMemo(() => {
    if (!leaderboardData) return [];

    if (matchType === 'stroke') {
      const newLeaderboard = leaderboardData.map(user => ({
        ...user,
        totalShotsOverPar: 0, // Default value when there are no scores
      }));

      console.log('Calculated Stroke Play Leaderboard:', newLeaderboard);
      return newLeaderboard;
    } else {
      const newLeaderboard = leaderboardData.map(user => ({
        ...user,
        points: 0, // Default value when there are no scores
      }));

      // console.log('Calculated Match Play Leaderboard:', newLeaderboard);
      return newLeaderboard;
    }
  }, [leaderboardData, matchType]);

  if (loading) {
    return <div className='leaderboard'>Loading...</div>;
  }

  if (failedAuth) {
    return (
      <div className='leaderboard'>
        <h2 className='leaderboard__subheader'>Leaderboard</h2>
        <p>Error fetching leaderboard data. Please log in again.</p>
      </div>
    );
  }

  return (
    <div className='leaderboard'>
      <h2 className='leaderboard__subheader'>Leaderboard</h2>
      <div className='leaderboard__column-filter'>
        <h6 className='leaderboard__column leaderboard__column-position'>Pos</h6>
        <h6 className='leaderboard__column leaderboard__column-name'>Player</h6>
        <h6 className='leaderboard__column leaderboard__column-total'>
          {matchType === 'stroke' ? '+/-' : 'Points'}
        </h6>
      </div>
      {calculatedLeaderboard &&
        calculatedLeaderboard.map((userData, index) => (
          <div className='leaderboard__rows' key={userData.user_id}>
            <p className='leaderboard__position'>{index + 1}</p>
            <p className='leaderboard__name'>{`${capitalize(userData.first_name)} ${capitalize(userData.last_name)}`}</p>
            <p className='leaderboard__total'>{matchType === 'stroke' ? userData.totalShotsOverPar : userData.points}</p>
          </div>
        ))}
    </div>
  );
};

export default LeaderBoard;
