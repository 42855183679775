import './UserProfile.scss';
import UserProfileCard from '../../components/UserProfileCard/UserProfileCard';
import HistoricalScoreCard from '../../components/HistoricalScoreCard/HistoricalScoreCard';
import UserStats from '../../components/UserStats/UserStats';


const UserProfile = () => {

    return (
        <div>
            <UserProfileCard />
            <UserStats />
            <HistoricalScoreCard />
        </div>
    );
};

export default UserProfile;