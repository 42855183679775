import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './FriendsList.scss';

const FriendsList = () => {
    const [failedAuth, setFailedAuth] = useState(false);
    const [friends, setFriends] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const navigate = useNavigate();

    const connection = process.env.REACT_APP_API_URL;

    const handleUnauthorized = () => {
        localStorage.removeItem('jwtToken');
        setFailedAuth(true);
        navigate('/login');
    };

    useEffect(() => {
        const fetchFriends = async () => {
            setLoading(true); // Set loading to true while fetching data
            try {
                const token = localStorage.getItem("jwtToken");
                if (!token) {
                    return handleUnauthorized();
                }

                const response = await axios.get(`${connection}/friends/list`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setFriends(response.data.friends);
                setPendingRequests(response.data.pendingRequests);
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    handleUnauthorized();
                } else {
                    console.error('Error fetching friends:', error);
                }
            } finally {
                setLoading(false); // Stop loading once data is fetched
            }
        };

        fetchFriends();
    }, []);

    const handleRespondRequest = async (friend_user_id, action) => {
        try {
            const token = localStorage.getItem("jwtToken");
            if (!token) {
                return handleUnauthorized();
            }
    
            // Ensure that the 'action' is either 'accepted' or 'rejected'
            if (!['accept', 'reject'].includes(action)) {
                alert('Invalid action');
                return;
            }
    
            // Send the response to the server as a PUT request
            await axios.put(`${connection}/friends/respond`, { friend_user_id, action }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
    
            if (action === 'accept') {
                // Find the accepted friend in the pending requests
                const acceptedFriend = pendingRequests.find(req => req.user_id === friend_user_id);
                
                // Add the accepted friend to the friends list
                setFriends(prevFriends => [...prevFriends, acceptedFriend]);
            }
            
            // Remove the friend from the pending requests list
            setPendingRequests(prev => prev.filter(request => request.user_id !== friend_user_id));
    
            // Optionally show a success message
            alert(`Friend request ${action === 'accept' ? 'accepted' : 'denied'}.`);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleUnauthorized();
            } else {
                console.error('Error responding to friend request:', error);
                alert('Error responding to friend request.');
            }
        }
    };

    if (failedAuth) {
        return <p>You have been logged out. Please <a href='/login'>login again</a>.</p>;
    }

    return (
        <section className='friends'>
            {loading ? (
                <p>Loading friends...</p>
            ) : (
                <>
                    <h3>Your Friends</h3>
                    {friends.length === 0 ? <p>No friends yet.</p> : (
                        friends.map(friend => (
                            <div className="friends--tile" key={friend.user_id}>
                                <img src={friend.profile_image_path} alt={friend.first_name} className="friends--tile__img" />
                                <div className="friends--tile__details">
                                    <div className="friends--name-location">
                                        <div className="friends--name">
                                            <h4 className="friends--name__first">{friend.first_name}</h4>
                                            <h4 className="friends--name__last">{friend.last_name}</h4>
                                        </div>
                                        <div className="friends--location">
                                            <h5 className="friends--location__city">{friend.city}</h5>
                                            <h5 className="friends--location__province">{friend.province}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}

                    <h3>Pending Requests</h3>
                    {pendingRequests.length === 0 ? <p>No pending requests.</p> : (
                        pendingRequests.map(request => (
                            <div className="friends--tile" key={request.user_id}>
                                <img src={request.profile_image_path} alt={request.first_name} className="friends--tile__img" />
                                <div className="friends--tile__details">
                                    <div className="friends--name-location">
                                        <div className="friends--name">
                                            <h4 className="friends--name__first">{request.first_name}</h4>
                                            <h4 className="friends--name__last">{request.last_name}</h4>
                                        </div>
                                        <div className="friends--location">
                                            <h5 className="friends--location__city">{request.city}</h5>
                                            <h5 className="friends--location__province">{request.province}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="friends--tile__buttons">
                                    <button
                                        onClick={() => handleRespondRequest(request.user_id, 'accept')}
                                        className='friends--button__accept'>
                                        <FaCheck />
                                    </button>
                                    <button
                                        onClick={() => handleRespondRequest(request.user_id, 'deny')}
                                        className='friends--button__deny'>
                                        <FaTimes />
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                </>
            )}
        </section>
    );
};

export default FriendsList;
