import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './UserStats.scss';
import { FaGolfBall, FaFlag, FaChartLine } from 'react-icons/fa';
import { GiGolfFlag, GiGolfClub, GiGolfTee } from 'react-icons/gi';
import { RiGolfBallFill } from 'react-icons/ri';

const connection = process.env.REACT_APP_API_URL;

// Component for individual statistic cards
const StatCard = ({ icon: Icon, label, value }) => (
    <div className="stat__card">
        <Icon className="stat__icon" />
        <div className="stat__values--container">
            <span className="stat__label">{label}</span>
            <span className="stat__value">{value || "N/A"}</span>
        </div>
    </div>
);

const UserStats = () => {
    const [user, setUser] = useState(null);
    const [stats, setStats] = useState({
        averageScore: null,
        averagePars: null,
        fairwaysHitPercentage: null,
        greensInRegulationPercentage: null,
        twoPuttsPercentage: null,
        handicapIndex: null,
    });
    const [handicapHistory, setHandicapHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [failedAuth, setFailedAuth] = useState(false);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const token = localStorage.getItem("jwtToken");
                if (!token) {
                    setFailedAuth(true);
                    setLoading(false);
                    return;
                }
    
                const headers = { Authorization: `Bearer ${token}` };
    
                // Fetch user data
                const userResponse = await axios.get(`${connection}/user`, { headers });
                const userData = userResponse.data;
                if (!userData?.user_id) {
                    throw new Error("User ID is missing");
                }
                setUser(userData); // Set user data after confirming its existence
    
                // Fetch stats
                const statsResponse = await axios.get(`${connection}/user/${userData.user_id}/stats`, { headers });
                const statsData = statsResponse.data;
    
                // Fetch handicap
                const handicapResponse = await axios.get(`${connection}/user/${userData.user_id}/handicap`, { headers });
                const handicapIndex = handicapResponse.data.handicapIndex || "Not available yet";
    
                // Fetch handicap history
                const historyResponse = await axios.get(`${connection}/user/${userData.user_id}/handicap-history`, { headers });
                const historyData = historyResponse.data.handicapHistory;
    
                // Update state after all data is successfully fetched
                setStats({ ...statsData, handicapIndex });
                setHandicapHistory(historyData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setError("Failed to load stats. Please try again.");
                setLoading(false);
            }
        };
    
        fetchStats();
    }, []);
     // Run once on component mount

    if (loading) return <div className="user-stats loading">Loading...</div>;
    if (failedAuth) return <div className="user-stats error">Authentication failed. Please log in.</div>;
    if (error) return <div className="user-stats error">{error}</div>;

    return (
        <div className="user__statistics">
            <h2 className="user__statistics--title">Your Stats</h2>
            <div className="user__statistics--container">
                <StatCard icon={GiGolfTee} label="Handicap" value={stats.handicapIndex} />
                <StatCard icon={FaChartLine} label="Score/18" value={stats.averageScore} />
                <StatCard icon={FaFlag} label="Pars/18" value={stats.averagePars} />
                <StatCard icon={FaGolfBall} label="Fairways Hit" value={`${stats.fairwaysHitPercentage || 0}%`} />
                <StatCard icon={RiGolfBallFill} label="G.I.R." value={`${stats.greensInRegulationPercentage || 0}%`} />
                <StatCard icon={GiGolfFlag} label="2-Putts" value={`${stats.twoPuttsPercentage || 0}%`} />
            </div>

            {/* <div className="handicap-history">
                <h3 className="handicap-history--title">Handicap History</h3>
                {handicapHistory.length > 0 ? (
                    <ul className="handicap-history--list">
                        {handicapHistory.map((entry, index) => (
                            <li key={index} className="handicap-history--item">
                                {new Date(entry.date_updated).toLocaleDateString()}: {entry.handicap_index}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No handicap history available.</p>
                )}
            </div> */}
        </div>
    );
};

export default UserStats;
