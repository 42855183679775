import './MakeTour.scss';
import CreateGroup from '../../components/CreateGroup/CreateGroup';

const MakeTour = () => {
    return (
        <div>
            <CreateGroup />
         </div>
    );
};

export default MakeTour;