import './Register.scss';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';
import ballLogo from '../../assets/images/logo.png';
import LegalPage from '../../pages/LegalPage/LegalPage';

const connection = process.env.REACT_APP_API_URL;

const Register = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showLegalModal, setShowLegalModal] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!firstname.trim()) newErrors.firstname = 'First name is required';
    if (!lastname.trim()) newErrors.lastname = 'Last name is required';
    if (!email.trim()) newErrors.email = 'Email is required';
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = 'Invalid email address';
    if (!password.trim()) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();

    if (validateForm() && agreedToTerms) {
      try {
        const registerObj = { firstname, lastname, email, password };
        await axios.post(`${connection}/register`, registerObj);
        navigate('/login');
      } catch (err) {
        console.error(err);
      }
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, agreedToTerms: 'You must agree to the terms and conditions' }));
    }
  };

  return (
    <section className="register">
      <div className="register__container">
        <section className="form">
          <form action="" className='form__register' onSubmit={handleRegister}>
            <img src={ballLogo} alt="Black & White Golf Ball" className='form__image'/>
            <h2 className="form__title">Join Your Tour</h2>

            <div className="form__input">
              <label htmlFor="first__name__input" className='form__label'>First Name:</label>
              <input type="text" name='first__name__input' className='first__name__input' placeholder='first name' 
                onChange={(event) => setFirstName(event.target.value)} autoComplete='on'/>
              {errors.firstname && <p className="error-message">{errors.firstname}</p>}
            </div>

            <div className="form__input">
              <label htmlFor="last__name__input" className='form__label'>Last Name:</label>
              <input type="text" name='last__name__input' className='last__name__input' placeholder='last name' 
                onChange={(event) => setLastName(event.target.value)} autoComplete='on'/>
              {errors.lastname && <p className="error-message">{errors.lastname}</p>}
            </div>

            <div className="form__input">
              <label htmlFor="email__input" className='form__label'>Email:</label>
              <input type="text" name='email__input' className='email__input' placeholder='email@email.com' 
                onChange={(event) => setEmail(event.target.value)} autoComplete='on'/>
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>

            <div className="form__input">
              <label htmlFor="password__input" className='form__label'>Password:</label>
              <input type="password" name='password__input' className='password__input' placeholder='password' 
                onChange={(event) => setPassword(event.target.value)} autoComplete='on'/>
              {errors.password && <p className="error-message">{errors.password}</p>}
            </div>

            {/* Terms & Conditions Agreement */}
            <div className="form__input form__input--checkbox">
              <input 
                type="checkbox" 
                id="terms" 
                checked={agreedToTerms}
                onChange={() => setAgreedToTerms(!agreedToTerms)}
              />
              <label htmlFor="terms" className="form__label">
                I agree to the <span className="terms__link" onClick={() => setShowLegalModal(true)}>Terms & Conditions and Privacy Policy</span>
              </label>
            </div>
            {errors.agreedToTerms && <p className="error-message">{errors.agreedToTerms}</p>}

            <button type='submit' className='register__button' disabled={!agreedToTerms}>Register</button>

            <span>
              <p className="login__text">Already have an account? <Link to='/login' className='login__link'>Sign In</Link></p>
            </span>
          </form>
        </section>

        {showLegalModal && (
          <div className="modal__overlay">
            <div className="modal__content">
              <button className="modal__close" onClick={() => setShowLegalModal(false)}>X</button>
              <div style={{ height: '80vh', overflowY: 'scroll' }}>
                <LegalPage />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Register;
