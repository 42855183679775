import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './EditProfile.scss';

const connection = process.env.REACT_APP_API_URL;

const EditProfile = () => {
  const [failedAuth, setFailedAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePath, setProfileImagePath] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getUser = async () => {
      try {
        const token = localStorage.getItem("jwtToken");

        if (!token) {
          navigate('/login');
          return setFailedAuth(true);
        }

        const userResponse = await axios.get(`${connection}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCurrentUser(userResponse.data);
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.first_name);
      setLastName(currentUser.last_name);
      setEmail(currentUser.email);
      setCity(currentUser.city);
      setProvince(currentUser.province);
      setCountry(currentUser.country);
      setProfileImagePath(currentUser.profile_image_path);
    }
  }, [currentUser]);

  const handleFileChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("jwtToken");

    if (!token) {
      navigate('/login');
      return setFailedAuth(true);
    }

    const formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('email', email);
    formData.append('city', city);
    formData.append('province', province);
    formData.append('country', country);

    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    try {
      const response = await axios.put(`${connection}/edit`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });

      // Update the local state with the new values
      setCurrentUser(response.data);
      setProfileImagePath(response.data.profile_image_path);
      navigate('/');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="edit">
      <h1 className="edit__title">Edit Profile</h1>
      <form onSubmit={handleUpdateProfile} className="edit-form">
        <div className="edit-form__image">
          <h3 className="edit-form__title">PROFILE IMAGE</h3>
          <img 
            src={profileImagePath ? `${connection}/${profileImagePath}?t=${Date.now()}` : 'default-profile-image-url'} 
            alt="Profile" 
            className="edit-form__new-image" 
          />
          <div className="edit-form__column">
            <label htmlFor="profileImage" className="edit-form__label">Upload Picture</label>
            <input type="file" name="profileImage" id="profileImage" className="edit-form__upload" onChange={handleFileChange} />
          </div>
        </div>

        <div className="edit-form__account">
          <h3 className="edit-form__title">ACCOUNT INFORMATION</h3>
          <div className="edit-form__section">
            <label htmlFor="firstname" className="edit-form__label">First Name</label>
            <input type="text" name="firstname" id="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="edit-form__input" />
          </div>
          <div className="edit-form__section">
            <label htmlFor="lastname" className="edit-form__label">Last Name</label>
            <input type="text" name="lastname" id="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} className="edit-form__input" />
          </div>
          <div className="edit-form__section">
            <label htmlFor="email" className="edit-form__label">Email</label>
            <input type="text" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="edit-form__input" />
          </div>
        </div>

        <div className="edit-form__location">
          <h3 className="edit-form__title">LOCATION INFORMATION</h3>
          <div className="edit-form__section">
            <label htmlFor="city" className="edit-form__label">City</label>
            <input type="text" name="city" id="city" value={city} onChange={(e) => setCity(e.target.value)} className="edit-form__input" />
          </div>
          <div className="edit-form__section">
            <label htmlFor="province" className="edit-form__label">Province/State</label>
            <input type="text" name="province" id="province" value={province} onChange={(e) => setProvince(e.target.value)} className="edit-form__input" />
          </div>
          <div className="edit-form__section">
            <label htmlFor="country" className="edit-form__label">Country</label>
            <input type="text" name="country" id="country" value={country} onChange={(e) => setCountry(e.target.value)} className="edit-form__input" />
          </div>
        </div>

        <button type="submit" className="edit-form__update">UPDATE</button>
      </form>
    </div>
  );
};

export default EditProfile;
