import React from 'react';
import { useParams } from 'react-router-dom';
import TournamentProgress from '../../components/TournamentProgress/TournamentProgress';
import TournamentLeaderboard from '../../components/TournamentLeaderboard/TournamentLeaderboard';
import './TournamentDetails.scss';

const TournamentDetails = () => {
  const { tournamentId } = useParams(); // Extract tournamentId from URL

  return (
    <div className="tournament-details__container">
      <h2 className="tournament-details__title">Tournament Details</h2>
      {/* User's Progress */}
      <TournamentProgress tournamentId={tournamentId} />
      
      {/* Tournament Leaderboard */}
      <TournamentLeaderboard tournamentId={tournamentId} />
    </div>
  );
};

export default TournamentDetails;
