import './LoginForm.scss';
import { Link, useNavigate } from 'react-router-dom';
import ballLogo from '../../assets/images/logo.png';
import axios from 'axios';
import { useState } from 'react';

const LoginForm = () => {

    const connection = process.env.REACT_APP_API_URL;



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const validateForm = () => {
        const newErrors = {};

        if (!email.trim()) {
            newErrors.email = 'Email is required';
        }

        if (!password.trim()) {
            newErrors.password = 'Password is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleLogin = async (event) => {
        event.preventDefault();
      
        if (validateForm()) {
          try {
            setLoading(true);
      
            const loginObj = {
              email: email,
              password: password
            }
      
            const response = await axios.post(`${connection}/login`, loginObj);
      
            const { token } = response.data;
      
            localStorage.setItem('jwtToken', token);
      
            setLoading(false);
            navigate('/');
          } catch (err) {
            setLoading(false);
      
            if (err.response && err.response.status === 401) {
              // Incorrect credentials
              setErrors({
                general: 'Invalid email or password',
                password: 'Incorrect password' // New line to set the password error
              });
            } else {
              console.log(err);
            }
          }
        }
      }

    return (
        <section className="login">
          <div className="login__header">
            <h4 className="login__header--title">The Tour</h4>
            <a href="/about" className="login__header--link">About</a>
          </div>
            <div className="login__container">
                <section className="form">
                    <form action="" className='form__login' onSubmit={handleLogin}>
                        <img src={ballLogo} alt="Black & White Golf Ball" className='form__image'/>

                        <h2 className="form__title">Welcome Back</h2>

                        <div className="form__input">
                            <div className="form__input--errors">
                                <label htmlFor="email__input" className='form__label'>Email:</label>
                                {errors.email && <p className="error-message">{errors.email}</p>}
                            </div>
                            <input type="text" name='email__input' className='email__input' placeholder='email@email.com' onChange={(event) => setEmail(event.target.value)} autoComplete='on'/>
                        </div>

                        <div className="form__input">
                            <div className="form__input--errors">
                                <label htmlFor="password__input" className='form__label'>Password:</label>
                                {errors.password && <p className="error-message">{errors.password}</p>}
                            </div>
                            <input type="password" name='password__input' className='password__input' placeholder='password' onChange={(event) => setPassword(event.target.value)} autoComplete='on'/>
                        </div>

                        <button type='submit' className='form__button' disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>

                        <span><p className="login__text">Not registered yet? <Link className='login__link' to='/register'>Sign Up</Link></p></span>
                    </form>
                </section>
            </div>
        </section>
    );
};

export default LoginForm;
