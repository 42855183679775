import React from 'react';
import './FriendsSearchPage.scss';
import FriendSearch from '../../components/FriendsSearch/FriendsSearch';

const FriendsSearchPage = () => {
    return (
        <div>
            <FriendSearch />
        </div>
    );
};

export default FriendsSearchPage;