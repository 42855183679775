import React from 'react';
import FriendsList from '../../components/FriendsList/FriendsList';
import './FriendsListPage.scss';

const FriendsListPage = () => {
    return (
        <div className='friends--section'>
            <section className="friends--section__header">
                <h2 className="friends--section__title">Your Friends</h2>
                <button className="friends--section__button"><a href="/friendsearch" className="friends--section__link">Find Friends</a></button>
            </section>
            <FriendsList />
        </div>
    );
};

export default FriendsListPage;