import React, { useState, useEffect } from 'react';
import './HistoricalScoreCard.scss';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGolfBall, faFlag, faChartPie } from '@fortawesome/free-solid-svg-icons';
import deleteIcon from '../../assets/icons/bin.png';

const connection = process.env.REACT_APP_API_URL;

const HistoricalScoreCard = () => {
  const [failedAuth, setFailedAuth] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [roundToDelete, setRoundToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRounds, setTotalRounds] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("jwtToken");
        if (!token) {
          return setFailedAuth(true);
        }

        const response = await axios.get(`${connection}/getscores?page=${currentPage}&limit=10`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        setCourseData(response.data.rounds);
        setTotalRounds(response.data.totalRounds);
      } catch (error) {
        console.error('Error fetching courses:', error);
        setFailedAuth(true);
      }
    };

    fetchData();

    const handleStorageChange = () => {
      if (localStorage.getItem('newScoreSubmitted') === 'true') {
        fetchData();
        localStorage.removeItem('newScoreSubmitted');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [currentPage]);

  function timestampToDdMmYyyy(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day} / ${month} / ${year}`;
  }

  const handleDeleteConfirmation = async () => {
    setShowConfirmation(false);

    try {
      const token = localStorage.getItem("jwtToken");

      if (!token) {
        return setFailedAuth(true);
      }

      console.log(`Attempting to delete round with id: ${roundToDelete}`);

      await axios.delete(`${connection}/deletescore/${roundToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Ensure this token is correct
        },
      });

      console.log(`Round with id: ${roundToDelete} deleted successfully`);

      const updatedCourseData = courseData.filter(data => data.round_id !== roundToDelete);
      setCourseData(updatedCourseData);
    } catch (error) {
      console.error('Error deleting score:', error);
    }
  };

  const handleShowConfirmation = (round_id) => {
    setRoundToDelete(round_id);
    setShowConfirmation(true);
  };

  const getStyledScore = (score, par) => {
    if (score === null) {
      return <span className="score-missing">-</span>; // Handle missing scores
    }
    if (score === par) {
      return <span className="score-par">{score}</span>;
    } else if (score < par) {
      return <span className="score-less-than-par">{score}</span>;
    }
    return <span className="score-more-than-par">{score}</span>;
  };

  const handleNextPage = () => {
    if ((currentPage * 10) < totalRounds) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (failedAuth) {
    return (
      <section className='loading'>
        <h3 className="loading__title">Loading...</h3>
        <button><a href='/login'>Login Again</a></button>
      </section>
    );
  }

  if (!courseData.length) {
    return (
      <section className='loading'>
        <h3 className="loading__title">No Rounds Played....yet</h3>
      </section>
    );
  }

  return (
    <div className='container'>
      {courseData.map((data) => (
        <section className='scorecard' key={data.round_id}>
          <div className="scorecard__titles">
            <h3 className="scorecard__course">{data.course_name}</h3>
            <button className='scorecard__delete' onClick={() => handleShowConfirmation(data.round_id)}>
              <img src={deleteIcon} alt="" className="scorecard__delete-icon" />
            </button>
          </div>

          {showConfirmation && roundToDelete === data.round_id && (
            <div className="modal__overlay">
              <div className="modal__front">
                <p className='modal__font'>Are you sure you want to delete this score?</p>
                <button className='modal__button' onClick={handleDeleteConfirmation}>Yes</button>
                <button className='modal__button' onClick={() => setShowConfirmation(false)}>Cancel</button>
              </div>
            </div>
          )}

          <table className='scorecard__table'>
            <tbody className='scorecard__body'>
              <tr className='scorecard__row row__1'>
                <th className='scorecard__row-title'>Hole</th>
                {data.holes.slice(0, 9).map((hole, index) => (
                  <td key={index} className='scorecard__hole'>{index + 1}</td>
                ))}
                <td className="scorecard__hole">In</td>
              </tr>
              <tr className='scorecard__row row__2'>
                <th className='scorecard__row-title'>Par</th>
                {data.holes.slice(0, 9).map((hole, index) => (
                  <td key={index} className="scorecard__par">{hole.par}</td>
                ))}
                <td className="scorecard__par">{data.holes.slice(0, 9).reduce((acc, hole) => acc + hole.par, 0)}</td>
              </tr>
              <tr className='scorecard__row row__3'>
                <th className='scorecard__row-title'>Score</th>
                {data.scores.slice(0, 9).map((score, index) => (
                  <td key={index} className="scorecard__score">{getStyledScore(score.hole_score, data.holes[index].par)}</td>
                ))}
                <td className="scorecard__score">{data.scores.slice(0, 9).reduce((acc, score) => acc + (score.hole_score || 0), 0)}</td>
              </tr>
              {data.holes.length > 9 && (
                <>
                  <tr className='scorecard__row row__4'>
                    <th className='scorecard__row-title'>Hole</th>
                    {data.holes.slice(9).map((hole, index) => (
                      <td key={index} className='scorecard__hole'>{index + 10}</td>
                    ))}
                    <td className="scorecard__hole">Out</td>
                  </tr>
                  <tr className='scorecard__row row__5'>
                    <th className='scorecard__row-title'>Par</th>
                    {data.holes.slice(9).map((hole, index) => (
                      <td key={index} className="scorecard__par">{hole.par}</td>
                    ))}
                    <td className="scorecard__par">{data.holes.slice(9).reduce((acc, hole) => acc + hole.par, 0)}</td>
                  </tr>
                  <tr className='scorecard__row row__6'>
                    <th className='scorecard__row-title'>Score</th>
                    {data.scores.slice(9).map((score, index) => (
                      <td key={index} className="scorecard__score">{getStyledScore(score.hole_score, data.holes[index + 9].par)}</td>
                    ))}
                    <td className="scorecard__score">{data.scores.slice(9).reduce((acc, score) => acc + (score.hole_score || 0), 0)}</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <div className="scorecard__date-wrapper">
            <h3 className="scorecard__date">{timestampToDdMmYyyy(data.game_date)}</h3>
          </div>
          {/* Display advanced stats */}
          <div className="advanced__stats">
            <div className="advanced__stats--data">
              <svg width="130" height="130" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#f1f2ef"
                  strokeWidth="10"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#5a7161"
                  strokeWidth="10"
                  strokeDasharray={`${(data.fairways_hit_percentage / 100) * 251}, 251`}
                  transform="rotate(-90 50 50)"
                />
              </svg>
              <div className="advanced__stats--label">
                <FontAwesomeIcon icon={faGolfBall} size="lg" />
                <span>F.H.</span>
                <span>{data.fairways_hit_percentage ? data.fairways_hit_percentage : 'N/A'}%</span>
              </div>
            </div>
            <div className="advanced__stats--data">
              <svg width="130" height="130" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#f1f2ef"
                  strokeWidth="10"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#5a7161"
                  strokeWidth="10"
                  strokeDasharray={`${(data.greens_in_regulation_percentage / 100) * 251}, 251`}
                  transform="rotate(-90 50 50)"
                />
              </svg>
              <div className="advanced__stats--label">
                <FontAwesomeIcon icon={faFlag} size="lg" />
                <span>G.I.R.</span>
                <span>{data.greens_in_regulation_percentage ? data.greens_in_regulation_percentage : 'N/A'}%</span>
              </div>
            </div>
            <div className="advanced__stats--data">
              <svg width="130" height="130" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#f1f2ef"
                  strokeWidth="10"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="40"
                  fill="none"
                  stroke="#5a7161"
                  strokeWidth="10"
                  strokeDasharray={`${(data.two_putts_percentage / 100) * 251}, 251`}
                  transform="rotate(-90 50 50)"
                />
              </svg>
              <div className="advanced__stats--label">
                <FontAwesomeIcon icon={faChartPie} size="lg" />
                <span>2-Putts</span>
                <span>{data.two_putts_percentage ? data.two_putts_percentage : 'N/A'}%</span>
              </div>
            </div>
          </div>
        </section>
      ))}
      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button onClick={handleNextPage} disabled={(currentPage * 10) >= totalRounds}>
          Next
        </button>
      </div>
    </div>
  );
};

export default HistoricalScoreCard;
